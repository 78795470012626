import { motion } from 'framer-motion'
import { StarIcon } from '@heroicons/react/24/solid'

const testimonials = [
  {
    content: "AIUMAN has completely transformed our content creation process. The AI detection and humanization tools are game-changers for our team.",
    author: "Sarah Johnson",
    role: "Content Director",
    company: "TechCorp",
    avatar: "https://i.pravatar.cc/100?img=1",
    rating: 5
  },
  {
    content: "The accuracy of the AI detection tool is impressive. It helps us maintain authenticity in our content while leveraging AI capabilities.",
    author: "Michael Chen",
    role: "Marketing Manager",
    company: "Growth Labs",
    avatar: "https://i.pravatar.cc/100?img=2",
    rating: 5
  },
  {
    content: "Voiceover AI has saved us countless hours in content production. The quality and natural sound are remarkable.",
    author: "Emma Williams",
    role: "Podcast Producer",
    company: "AudioMax",
    avatar: "https://i.pravatar.cc/100?img=3",
    rating: 5
  }
]

export default function Testimonials() {
  return (
    <section className="py-24 relative overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold mb-4">What Our Users Say</h2>
          <p className="text-xl text-gray-400">Join thousands of satisfied creators</p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <motion.div
              key={testimonial.author}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="glass rounded-2xl p-6 relative"
            >
              {/* Rating */}
              <div className="flex gap-1 mb-4">
                {[...Array(testimonial.rating)].map((_, i) => (
                  <StarIcon key={i} className="h-5 w-5 text-yellow-500" />
                ))}
              </div>

              {/* Content */}
              <p className="text-gray-300 mb-6">{testimonial.content}</p>

              {/* Author */}
              <div className="flex items-center gap-4">
                <img 
                  src={testimonial.avatar} 
                  alt={testimonial.author}
                  className="w-12 h-12 rounded-full"
                />
                <div>
                  <div className="font-bold">{testimonial.author}</div>
                  <div className="text-sm text-gray-400">{testimonial.role}</div>
                  <div className="text-sm text-purple-400">{testimonial.company}</div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  )
}
