import { Link } from 'react-router-dom'

const legalLinks = [
  { name: 'Privacy Policy', href: '/privacy-policy' },
  { name: 'Terms & Conditions', href: '/terms' },
  { name: 'Refund Policy', href: '/refund-policy' },
]

export default function Footer() {
  return (
    <footer className="bg-[#0D0118] border-t border-purple-500/20" aria-labelledby="footer-heading">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Address */}
          <div className="space-y-4">
            <div className="text-2xl font-bold gradient-text">AIUMAN</div>
            <div className="text-gray-400 text-sm space-y-2">
              <p>NECTOR VENTURES PRIVATE LIMITED</p>
              <p>80, Karunakunj,</p>
              <p>Dwarka sector-3,</p>
              <p>New Delhi-110078</p>
            </div>
          </div>

          {/* Legal Links */}
          <div className="md:text-right">
            <h3 className="text-sm font-semibold text-gray-200 tracking-wider uppercase mb-4">Legal</h3>
            <ul role="list" className="space-y-4">
              {legalLinks.map((item) => (
                <li key={item.name}>
                  <Link to={item.href} className="text-base text-gray-400 hover:text-purple-400">
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        
        <div className="mt-12 border-t border-purple-500/20 pt-8">
          <p className="text-base text-gray-400 text-center">
            &copy; {new Date().getFullYear()} AIUMAN. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}
