import { motion } from 'framer-motion'
import { CheckIcon } from '@heroicons/react/24/outline'

const features = [
  {
    title: 'Advanced AI Detection',
    description: 'Detect AI-generated content with unmatched accuracy using our proprietary algorithms',
    items: [
      'Real-time content analysis',
      'Plagiarism detection',
      'Style consistency check',
      'Contextual understanding'
    ]
  },
  {
    title: 'Natural Language Processing',
    description: 'Transform robotic text into natural, engaging content that resonates with readers',
    items: [
      'Context-aware rewriting',
      'Tone adjustment',
      'Grammar enhancement',
      'Style customization'
    ]
  },
  {
    title: 'Multi-modal Generation',
    description: 'Create various types of content with our comprehensive AI suite',
    items: [
      'Text generation',
      'Image creation',
      'Voice synthesis',
      'Chat responses'
    ]
  }
]

export default function Features() {
  return (
    <section className="py-24 relative overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold mb-4">Powerful Features</h2>
          <p className="text-xl text-gray-400">Everything you need to create exceptional content</p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={feature.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="glass rounded-2xl p-6"
            >
              <h3 className="text-2xl font-bold mb-4">{feature.title}</h3>
              <p className="text-gray-400 mb-6">{feature.description}</p>
              <ul className="space-y-3">
                {feature.items.map((item) => (
                  <li key={item} className="flex items-start gap-3">
                    <CheckIcon className="h-6 w-6 text-purple-400 flex-shrink-0" />
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  )
}
