import { useState } from 'react'
import { motion } from 'framer-motion'
import { 
  PhotoIcon,
  ArrowPathIcon,
  SparklesIcon,
  ArrowDownTrayIcon
} from '@heroicons/react/24/outline'
import AIServiceStatus from '../components/AIServiceStatus'

const styles = [
  { id: 'realistic', name: 'Realistic', description: 'Photorealistic style' },
  { id: 'artistic', name: 'Artistic', description: 'Digital art style' },
  { id: '3d', name: '3D Render', description: '3D rendered style' },
  { id: 'cartoon', name: 'Cartoon', description: 'Cartoon/Anime style' },
  { id: 'sketch', name: 'Sketch', description: 'Hand-drawn style' },
  { id: 'abstract', name: 'Abstract', description: 'Abstract art style' }
]

const sampleImages = [
  {
    url: 'https://images.pexels.com/photos/5473950/pexels-photo-5473950.jpeg',
    prompt: 'Futuristic cityscape at night with neon lights',
    style: 'Realistic'
  },
  {
    url: 'https://images.pexels.com/photos/8386440/pexels-photo-8386440.jpeg',
    prompt: 'Abstract digital art with flowing colors',
    style: 'Abstract'
  },
  {
    url: 'https://images.pexels.com/photos/8566472/pexels-photo-8566472.jpeg',
    prompt: 'Magical forest with glowing elements',
    style: 'Artistic'
  },
  {
    url: 'https://images.pexels.com/photos/7267852/pexels-photo-7267852.jpeg',
    prompt: 'Cyberpunk character portrait',
    style: '3D Render'
  },
  {
    url: 'https://images.pexels.com/photos/8100784/pexels-photo-8100784.jpeg',
    prompt: 'Surreal landscape with floating islands',
    style: 'Digital Art'
  },
  {
    url: 'https://images.pexels.com/photos/7267584/pexels-photo-7267584.jpeg',
    prompt: 'Sci-fi interior with holographic displays',
    style: 'Realistic'
  }
]

export default function ImagineAI() {
  const [prompt, setPrompt] = useState('')
  const [selectedStyle, setSelectedStyle] = useState(styles[0])
  const [size, setSize] = useState('1024x1024')
  const [loading, setLoading] = useState(false)
  const [generatedImage, setGeneratedImage] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)

  const handleGenerate = async () => {
    if (!prompt.trim() || loading) return

    setLoading(true)
    setError(null)

    try {
      // Simulate image generation with a random sample image
      await new Promise(resolve => setTimeout(resolve, 2000))
      const randomImage = sampleImages[Math.floor(Math.random() * sampleImages.length)]
      setGeneratedImage(randomImage.url)
    } catch (err) {
      console.error("Image generation error:", err)
      setError("Failed to generate image. Please try again.")
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="bg-[#0D0118] min-h-screen pt-32">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <AIServiceStatus />

        <div className="text-center mb-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="inline-flex items-center px-6 py-2 rounded-full glass text-purple-300 border border-purple-500/20 mb-8"
          >
            <PhotoIcon className="h-5 w-5 mr-2" />
            AI Image Generation
          </motion.div>

          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
            className="text-5xl sm:text-6xl font-bold mb-8"
          >
            Transform Ideas Into
            <br />
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-pink-400 via-rose-400 to-red-500">
              Stunning Visuals
            </span>
          </motion.h1>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="max-w-4xl mx-auto glass rounded-2xl p-8"
          >
            {/* Style Selection */}
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 mb-6">
              {styles.map((style) => (
                <button
                  key={style.id}
                  onClick={() => setSelectedStyle(style)}
                  className={`p-4 rounded-xl text-left transition-colors ${
                    selectedStyle.id === style.id
                      ? 'bg-pink-500/20 border border-pink-500/50'
                      : 'glass hover:bg-purple-900/30'
                  }`}
                >
                  <div className="font-medium">{style.name}</div>
                  <div className="text-sm text-gray-400">{style.description}</div>
                </button>
              ))}
            </div>

            {/* Image Settings */}
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-400 mb-2">Image Size</label>
              <select
                value={size}
                onChange={(e) => setSize(e.target.value)}
                className="w-full bg-purple-900/20 rounded-xl p-4 text-gray-100 focus:outline-none focus:ring-2 focus:ring-purple-500/50"
              >
                <option value="1024x1024">1024x1024 - Square</option>
                <option value="1024x1792">1024x1792 - Portrait</option>
                <option value="1792x1024">1792x1024 - Landscape</option>
              </select>
            </div>

            {/* Prompt Input */}
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-400 mb-2">Describe your image</label>
              <textarea
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                className="w-full h-32 bg-purple-900/20 rounded-xl p-4 text-gray-100 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500/50"
                placeholder="Describe the image you want to create in detail..."
              />
            </div>

            {/* Generate Button */}
            <button
              onClick={handleGenerate}
              disabled={loading || !prompt.trim()}
              className="w-full px-8 py-4 rounded-xl font-bold text-white bg-gradient-to-r from-pink-500 to-rose-500 hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-2"
            >
              {loading ? (
                <>
                  <ArrowPathIcon className="h-5 w-5 animate-spin" />
                  Generating...
                </>
              ) : (
                <>
                  <SparklesIcon className="h-5 w-5" />
                  Generate Image
                </>
              )}
            </button>

            {/* Error Display */}
            {error && (
              <div className="mt-6 p-4 bg-red-500/10 border border-red-500/20 rounded-xl">
                <p className="text-red-400">{error}</p>
              </div>
            )}

            {/* Generated Image Display */}
            {generatedImage && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                className="mt-6 p-6 glass rounded-xl"
              >
                <div className="relative aspect-square rounded-lg overflow-hidden">
                  <img
                    src={generatedImage}
                    alt="Generated artwork"
                    className="w-full h-full object-cover"
                  />
                  <button
                    onClick={() => window.open(generatedImage, '_blank')}
                    className="absolute bottom-4 right-4 p-2 rounded-lg glass hover:bg-white/20 transition-colors"
                  >
                    <ArrowDownTrayIcon className="h-5 w-5" />
                  </button>
                </div>
              </motion.div>
            )}
          </motion.div>

          {/* Sample Gallery */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className="mt-16"
          >
            <h2 className="text-2xl font-bold mb-8">Example Generations</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {sampleImages.map((image, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ delay: index * 0.1 }}
                  className="glass rounded-xl overflow-hidden group cursor-pointer"
                >
                  <div className="relative aspect-square">
                    <img
                      src={image.url}
                      alt={image.prompt}
                      className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-end p-4">
                      <div>
                        <div className="text-sm font-medium text-primary-400">
                          {image.style}
                        </div>
                        <p className="text-white text-sm">
                          {image.prompt}
                        </p>
                      </div>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  )
}
