import { loadStripe, Stripe } from '@stripe/stripe-js'
import { stripeConfig } from '../config/stripe'

let stripePromise: Promise<Stripe | null> | null = null

export const getStripe = () => {
  if (!stripePromise) {
    if (!stripeConfig.publishableKey) throw new Error('Stripe publishable key missing')
    stripePromise = loadStripe(stripeConfig.publishableKey)
  }
  return stripePromise
}

export const createPaymentIntent = async (priceId: string, amount: string) => {
  try {
    const response = await fetch('/api/create-payment-intent', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ priceId, amount })
    })
    
    if (!response.ok) throw new Error('Payment intent creation failed')
    const { clientSecret } = await response.json()
    return clientSecret
  } catch (error) {
    console.error('Payment intent error:', error)
    throw error
  }
}

export const createCheckoutSession = async (priceId: string) => {
  const stripe = await getStripe()
  if (!stripe) throw new Error('Stripe failed to load')

  const { error } = await stripe.redirectToCheckout({
    lineItems: [{ price: priceId, quantity: 1 }],
    mode: 'subscription',
    successUrl: `${window.location.origin}/success`,
    cancelUrl: `${window.location.origin}/payment-methods`,
    billingAddressCollection: 'required',
  })

  if (error) throw error
}