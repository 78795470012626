import { motion } from 'framer-motion'
import { XCircleIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

export default function Cancel() {
  return (
    <div className="bg-[#0D0118] min-h-screen pt-32 pb-16">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          className="glass rounded-2xl p-8"
        >
          <XCircleIcon className="h-16 w-16 text-red-500 mx-auto mb-6" />
          <h1 className="text-3xl font-bold mb-4">Payment Cancelled</h1>
          <p className="text-gray-300 mb-8">
            Your payment was cancelled. If you have any questions, please contact our support team.
          </p>
          <Link
            to="/pricing"
            className="inline-block px-8 py-4 rounded-xl font-bold text-white bg-gradient-to-r from-purple-500 to-violet-500 hover:opacity-90 transition-opacity"
          >
            Return to Pricing
          </Link>
        </motion.div>
      </div>
    </div>
  )
}
