import { motion } from 'framer-motion'
import Pricing from '../components/Pricing'

export default function PricingPage() {
  return (
    <div className="bg-[#0D0118] min-h-screen pt-32">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold mb-6">
            Choose Your <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-fuchsia-500">AI Journey</span>
          </h1>
          <p className="text-xl text-gray-400 max-w-3xl mx-auto">
            Select the perfect plan for your needs. All plans include a 14-day free trial with full access to all features.
          </p>
        </motion.div>
      </div>
      
      <Pricing />
      
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 pb-24">
        <div className="glass rounded-2xl p-8">
          <h3 className="text-2xl font-bold mb-4 text-center">Frequently Asked Questions</h3>
          <div className="space-y-6">
            <div>
              <h4 className="font-semibold mb-2">Can I switch plans later?</h4>
              <p className="text-gray-400">Yes, you can upgrade or downgrade your plan at any time. Changes will be reflected in your next billing cycle.</p>
            </div>
            <div>
              <h4 className="font-semibold mb-2">What payment methods do you accept?</h4>
              <p className="text-gray-400">We accept all major credit cards, PayPal, and various local payment methods.</p>
            </div>
            <div>
              <h4 className="font-semibold mb-2">Is there a long-term commitment?</h4>
              <p className="text-gray-400">No, all plans are monthly and you can cancel anytime. No long-term contracts required.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
