import { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { 
  MagnifyingGlassIcon, 
  UserIcon, 
  PencilSquareIcon,
  MicrophoneIcon,
  PhotoIcon,
  ChatBubbleBottomCenterTextIcon
} from '@heroicons/react/24/outline'

const tools = [
  {
    id: 'detector',
    name: 'AI Detector',
    description: 'Detect AI-generated content with 99.9% accuracy using our advanced algorithms',
    icon: MagnifyingGlassIcon,
    gradient: 'from-purple-500 to-violet-500',
    demo: '/tool-demos/detector.mp4',
    features: ['Real-time analysis', 'Accuracy score', 'Detailed reports', 'Batch processing']
  },
  {
    id: 'humanizer',
    name: 'AI Humanizer',
    description: 'Transform AI content into natural, human-like text that connects with readers',
    icon: UserIcon,
    gradient: 'from-violet-500 to-fuchsia-500',
    demo: '/tool-demos/humanizer.mp4',
    features: ['Style adaptation', 'Tone adjustment', 'Natural flow', 'Context preservation']
  },
  {
    id: 'writer',
    name: 'AI Writer',
    description: 'Create engaging content with our AI-powered writing assistant',
    icon: PencilSquareIcon,
    gradient: 'from-fuchsia-500 to-pink-500',
    demo: '/tool-demos/writer.mp4',
    features: ['Smart suggestions', 'SEO optimization', 'Multiple formats', 'Brand voice']
  },
  {
    id: 'voiceover',
    name: 'Voiceover AI',
    description: 'Convert text to natural-sounding speech with emotional intelligence',
    icon: MicrophoneIcon,
    gradient: 'from-pink-500 to-rose-500',
    demo: '/tool-demos/voiceover.mp4',
    features: ['Natural voices', 'Emotion control', 'Multiple languages', 'Custom voices']
  },
  {
    id: 'imagine',
    name: 'Imagine AI',
    description: 'Generate and edit stunning images with state-of-the-art AI',
    icon: PhotoIcon,
    gradient: 'from-rose-500 to-orange-500',
    demo: '/tool-demos/imagine.mp4',
    features: ['Image generation', 'Style transfer', 'Photo editing', 'Batch processing']
  },
  {
    id: 'chat',
    name: 'Chat AI',
    description: 'Build engaging chatbots and virtual assistants',
    icon: ChatBubbleBottomCenterTextIcon,
    gradient: 'from-orange-500 to-amber-500',
    demo: '/tool-demos/chat.mp4',
    features: ['Smart responses', 'Personality customization', 'Multi-language', 'Integration ready']
  }
]

export default function ToolsShowcase() {
  const [activeTool, setActiveTool] = useState(tools[0])

  return (
    <section className="py-24 relative overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold mb-4">Explore Our Tools</h2>
          <p className="text-xl text-gray-400">Discover the power of each AI tool</p>
        </div>

        <div className="grid lg:grid-cols-[400px,1fr] gap-8">
          {/* Tools List */}
          <div className="space-y-4">
            {tools.map((tool) => (
              <motion.div
                key={tool.id}
                onClick={() => setActiveTool(tool)}
                className={`glass rounded-xl p-4 cursor-pointer transition-all duration-300 ${
                  activeTool.id === tool.id 
                    ? 'bg-purple-900/40 border border-purple-500/50' 
                    : 'hover:bg-purple-900/20'
                }`}
              >
                <div className="flex items-center gap-4">
                  <div className={`h-10 w-10 rounded-lg bg-gradient-to-r ${tool.gradient} flex items-center justify-center`}>
                    <tool.icon className="h-5 w-5 text-white" />
                  </div>
                  <div>
                    <h3 className="font-bold">{tool.name}</h3>
                    <p className="text-sm text-gray-400">{tool.description}</p>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>

          {/* Tool Preview */}
          <div className="glass rounded-2xl p-8 relative overflow-hidden">
            <AnimatePresence mode="wait">
              <motion.div
                key={activeTool.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
                className="space-y-6"
              >
                <div className="flex items-center gap-4 mb-8">
                  <div className={`h-12 w-12 rounded-xl bg-gradient-to-r ${activeTool.gradient} flex items-center justify-center`}>
                    <activeTool.icon className="h-6 w-6 text-white" />
                  </div>
                  <div>
                    <h3 className="text-2xl font-bold">{activeTool.name}</h3>
                    <p className="text-gray-400">{activeTool.description}</p>
                  </div>
                </div>

                <div className="aspect-video rounded-xl overflow-hidden bg-purple-900/40">
                  {/* Replace with actual demo video/content */}
                  <div className="w-full h-full flex items-center justify-center text-gray-500">
                    Tool Demo Preview
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4 mt-6">
                  {activeTool.features.map((feature) => (
                    <div key={feature} className="flex items-center gap-2">
                      <div className="h-2 w-2 rounded-full bg-purple-400" />
                      <span className="text-gray-300">{feature}</span>
                    </div>
                  ))}
                </div>
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </div>
    </section>
  )
}
