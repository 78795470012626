import { motion } from 'framer-motion'

const steps = [
  {
    number: '01',
    title: 'Choose Your Tool',
    description: 'Select from our suite of AI-powered tools based on your content needs'
  },
  {
    number: '02',
    title: 'Input Your Content',
    description: 'Provide your text, prompt, or requirements for AI processing'
  },
  {
    number: '03',
    title: 'AI Processing',
    description: 'Our advanced AI analyzes and transforms your content in seconds'
  },
  {
    number: '04',
    title: 'Review & Export',
    description: 'Fine-tune the results and export your enhanced content'
  }
]

export default function HowItWorks() {
  return (
    <section className="py-24 relative overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold mb-4">How It Works</h2>
          <p className="text-xl text-gray-400">Simple steps to transform your content</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {steps.map((step, index) => (
            <motion.div
              key={step.number}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="glass rounded-2xl p-6 relative"
            >
              <div className="text-4xl font-bold text-purple-400 mb-4">{step.number}</div>
              <h3 className="text-xl font-bold mb-2">{step.title}</h3>
              <p className="text-gray-400">{step.description}</p>
              {index < steps.length - 1 && (
                <div className="hidden lg:block absolute top-1/2 -right-12 w-24 h-[2px] bg-gradient-to-r from-purple-500 to-transparent" />
              )}
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  )
}
