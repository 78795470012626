import { useEffect, useState } from 'react'
import openAIService from '../lib/openai'

export default function AIServiceStatus() {
  const [apiConnected, setApiConnected] = useState(false)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const checkConnection = async () => {
      try {
        if (!import.meta.env.VITE_OPENAI_API_KEY) {
          throw new Error('API key not configured')
        }
        await openAIService.testConnection()
        setApiConnected(true)
        setError(null)
      } catch (err) {
        console.error("Connection error:", err)
        setApiConnected(false)
        setError(err instanceof Error ? err.message : "API connection failed")
      }
    }
    checkConnection()
  }, [])

  return (
    <div className="flex items-center justify-center gap-2 mb-4">
      <div className={`w-2 h-2 rounded-full ${apiConnected ? 'bg-green-500' : 'bg-red-500'}`} />
      <span className="text-sm text-gray-400">
        {apiConnected ? 'AI Service Connected' : 'AI Service Unavailable'}
      </span>
      {error && <span className="text-sm text-red-400">({error})</span>}
    </div>
  )
}
