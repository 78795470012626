import MarkdownPolicy from '../components/MarkdownPolicy'

const refundPolicyMd = `
# Refund Policy

*Last Updated: January 2024*

## Introduction

At AIUMAN, we strive to ensure complete satisfaction with our services. This policy outlines our refund procedures and eligibility criteria.

## 1. Refund Eligibility

### 1.1 Qualifying Circumstances
- Service unavailability (>24 hours)
- Technical issues preventing usage
- Billing errors
- Subscription cancellation (within 7 days)

### 1.2 Non-Eligible Cases
- Used credits or services
- Custom development work
- Expired trial periods
- Terms of service violations

## 2. Refund Process

### 2.1 Request Procedure
1. Contact customer support
2. Provide account details
3. Explain refund reason
4. Submit relevant documentation

### 2.2 Processing Timeline
- Review period: 1-2 business days
- Processing time: 5-7 business days
- Bank settlement: 2-5 business days
- Total maximum duration: 14 days

## 3. Refund Methods

### 3.1 Standard Refunds
- Original payment method
- Full amount for eligible cases
- Pro-rated calculations when applicable
- Currency conversion rates apply

### 3.2 Alternative Options
- Account credits
- Service extensions
- Plan adjustments
- Alternative payment methods

## 4. Cancellation Policy

### 4.1 Subscription Cancellation
- Cancel anytime through dashboard
- No partial period refunds
- Access until period end
- Renewal prevention

### 4.2 Notice Requirements
- 24-hour minimum notice
- Written cancellation request
- Account verification
- Reason documentation

## 5. Special Circumstances

### 5.1 Service Outages
- Credit compensation
- Service extension
- Priority support
- Alternative solutions

### 5.2 Billing Disputes
- Evidence requirements
- Investigation process
- Resolution timeline
- Appeal procedures

## 6. Contact Information

### Support Channels
- Email: support@aiuman.com
- Phone: +91-XXXXXXXXXX

### Registered Office
NECTOR VENTURES PRIVATE LIMITED  
80, Karunakunj,  
Dwarka sector-3,  
New Delhi-110078  
India

---

*This policy is effective as of January 1, 2024*
`

export default function RefundPolicy() {
  return <MarkdownPolicy title="Refund Policy" markdown={refundPolicyMd} />
}
