import MarkdownPolicy from '../components/MarkdownPolicy'

const privacyPolicyMd = `
*Effective Date: December 1, 2024*

## Understanding Your Privacy

We believe in being transparent about how we handle your data. Here's what you need to know:

### Information We Collect

- **Account Information**
  - Email address and profile details
  - Account preferences
  - Authentication credentials

- **Usage Information**
  - How you use our AI tools
  - Service usage patterns
  - Performance metrics

### How We Use Your Data

We use your information to:
- Provide and improve our AI services
- Process your transactions
- Send important service updates
- Enhance user experience
- Protect against fraud

## Data Protection

Your security is our top priority. We implement:

### Security Measures
- End-to-end encryption
- Regular security audits
- Strict access controls
- Continuous monitoring

### Data Storage
- Secure cloud infrastructure
- Regular backups
- Encrypted databases
- Geographic redundancy

## Your Rights

You have complete control over your data:

### Access Rights
- View your personal data
- Download your information
- Request data corrections
- Export your content

### Control Options
- Update your preferences
- Opt-out of communications
- Delete your account
- Manage cookies

## Data Sharing

We're selective about data sharing:

### When We Share
- With essential service providers
- For legal requirements
- With your explicit consent

### We Never
- Sell your personal data
- Share without permission
- Use data for advertising
- Allow unauthorized access

## Updates to Policy

We keep our privacy policy current:
- Regular reviews and updates
- Email notifications of changes
- 30-day notice for major updates
- Archive of previous versions

*This policy was last updated on December 1, 2024*
`

export default function PrivacyPolicy() {
  return <MarkdownPolicy title="Privacy Policy" markdown={privacyPolicyMd} />
}
