import { useState } from 'react';
import { motion } from 'framer-motion';
import { CheckIcon } from '@heroicons/react/24/outline';
import { Loader2, ArrowRight, X } from 'lucide-react';

const plans = [
  {
    name: "Basic",
    price: "999",
    description: "Perfect for individual creators",
    popular: false,
    features: [
      "Access to all 6 AI tools",
      "100,000 words for AI Writer",
      "50 images with Imagine AI",
      "30 minutes of Voice Over AI",
      "100,000 words AI Humanizer",
      "Limited Conversational AI usage",
      "Basic AI Detector scans",
      "Standard email support",
      "Basic analytics dashboard"
    ]
  },
  {
    name: "Professional",
    price: "1999",
    description: "Ideal for professionals & small teams",
    popular: true,
    features: [
      "Everything in Basic, plus:",
      "500,000 words for AI Writer",
      "100 images with Imagine AI",
      "90 minutes of Voice Over AI",
      "300,000 words AI Humanizer",
      "Advanced Conversational AI",
      "Priority AI Detector scans",
      "Priority support",
      "Advanced analytics",
      "Team collaboration features",
    ]
  },
  {
    name: "Enterprise",
    price: "4999",
    description: "For agencies & large teams",
    popular: false,
    features: [
      "Everything in Professional, plus:",
      "Unlimited AI Writer usage",
      "Unlimited Imagine AI generations",
      "180 minutes of Voice Over AI",
      "Unlimited AI Humanizer usage",
      "Custom Conversational AI models",
      "Enterprise AI Detector features",
      "24/7 dedicated support",
      "White-label options",
      "Advanced team management",
    ]
  }
];

export default function Pricing() {


  // State definitions with proper types
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  const [formData, setFormData] = useState<FormData>({
    fullName: '',
    email: '',
    company: '',
    phoneNumber: '',
    message: ''
  });
  

  interface Plan {
    name: string;
    price: string;
    description: string;
    popular: boolean;
    features: string[];
  }

  interface FormData {
    fullName: string;
    email: string;
    company: string;
    phoneNumber: string;
    message: string;
  }

  interface FormData {
    fullName: string;
    email: string;
    company: string;
    phoneNumber: string;
    message: string;
  }
  
  interface Plan {
    name: string;
    price: string;
    description: string;
    popular: boolean;
    features: string[];
  }
  
  const handleLeadSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setLoading(true);
  
      try {
        const formUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSftykgQYSfedSXusIDzufYEE6suFs65_y-OvbUV-xxfOl1ZfQ/formResponse';
  
        const params = new URLSearchParams({
          'entry.1328513495': formData.fullName,     
          'entry.496803778': formData.email,        
          'entry.1556197605': formData.company,      
          'entry.327900761': formData.phoneNumber,    
          'entry.253436706': formData.message,      
          'entry.1530803317': selectedPlan!.name,     
          'entry.972028616': selectedPlan!.price    
        });
  
        await fetch(formUrl + '?' + params.toString(), {
          method: 'POST',
          mode: 'no-cors',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });
  
        const initialFormData: FormData = {
          fullName: '',
          email: '',
          company: '',
          phoneNumber: '',
          message: ''
        };
  
        setFormData(initialFormData);
        setSelectedPlan(null);
        alert('Thank you for your interest! We will contact you soon.');
      } catch (error) {
        console.error('Error submitting form:', error);
        alert('Failed to submit form. Please try again.');
      } finally {
        setLoading(false);
      }
  };
  

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prev: FormData) => ({
      ...prev,
      [name]: value
    }));
  };

  // Lead capture form modal
  if (selectedPlan) {
    return (
      <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
        <div className="max-w-2xl w-full">
          <div className="backdrop-blur-sm bg-purple-900/20 border border-purple-500/20 rounded-2xl p-8 relative">
            <button
              onClick={() => setSelectedPlan(null)}
              className="absolute right-4 top-4 text-gray-400 hover:text-white"
            >
              <X className="h-6 w-6" />
            </button>

            <h2 className="text-3xl font-bold text-center mb-2">Get Started with {selectedPlan.name}</h2>
            <p className="text-gray-400 text-center mb-8">Tell us about yourself and we'll get in touch</p>

            <form onSubmit={handleLeadSubmit} className="space-y-6">
  <div className="space-y-4">
    <div>
      <label className="text-sm font-medium text-gray-200 block mb-1.5">
        Full Name *
      </label>
      <input
        required
        type="text"
        name="fullName"
        value={formData.fullName}
        onChange={handleChange}
        className="w-full px-4 py-3 rounded-lg bg-purple-900/30 border border-purple-500/30 
        text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500/50 
        focus:border-transparent transition-all duration-200"
        placeholder="John Doe"
      />
    </div>

    <div>
      <label className="text-sm font-medium text-gray-200 block mb-1.5">
        Email *
      </label>
      <input
        required
        type="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        className="w-full px-4 py-3 rounded-lg bg-purple-900/30 border border-purple-500/30 
        text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500/50 
        focus:border-transparent transition-all duration-200"
        placeholder="john@example.com"
      />
    </div>

    <div>
      <label className="text-sm font-medium text-gray-200 block mb-1.5">
        Company *
      </label>
      <input
        required
        type="text"
        name="company"
        value={formData.company}
        onChange={handleChange}
        className="w-full px-4 py-3 rounded-lg bg-purple-900/30 border border-purple-500/30 
        text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500/50 
        focus:border-transparent transition-all duration-200"
        placeholder="Company Name"
      />
    </div>

    <div>
      <label className="text-sm font-medium text-gray-200 block mb-1.5">
        Phone Number *
      </label>
      <input
        required
        type="tel"
        name="phoneNumber"
        value={formData.phoneNumber}
        onChange={handleChange}
        className="w-full px-4 py-3 rounded-lg bg-purple-900/30 border border-purple-500/30 
        text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500/50 
        focus:border-transparent transition-all duration-200"
        placeholder="+1 (555) 000-0000"
      />
    </div>

    <div>
      <label className="text-sm font-medium text-gray-200 block mb-1.5">
        Message
      </label>
      <textarea
        name="message"
        value={formData.message}
        onChange={handleChange}
        className="w-full px-4 py-3 rounded-lg bg-purple-900/30 border border-purple-500/30 
        text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500/50 
        focus:border-transparent transition-all duration-200 resize-none min-h-[128px]"
        placeholder="Any specific requirements or questions?"
      />
    </div>
  </div>

  <button
    type="submit"
    disabled={loading}
    className="w-full px-8 py-4 rounded-xl font-bold text-white bg-gradient-to-r 
    from-purple-500 to-violet-500 hover:opacity-90 transition-all duration-300 
    flex items-center justify-center gap-2 disabled:opacity-50"
  >
    {loading ? (
      <>
        <Loader2 className="h-5 w-5 animate-spin" />
        Sending...
      </>
    ) : (
      <>
        Submit
        <ArrowRight className="h-5 w-5" />
      </>
    )}
  </button>
</form>
          </div>
        </div>
      </div>
    );
  }

  return (
    <section className="py-24 relative overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-b from-purple-600/10 via-violet-500/5 to-transparent" />
        <div className="absolute top-0 left-0 w-full h-full" style={{
          backgroundImage: `linear-gradient(to right, rgba(139, 92, 246, 0.05) 1px, transparent 1px),
                           linear-gradient(to bottom, rgba(139, 92, 246, 0.05) 1px, transparent 1px)`,
          backgroundSize: '64px 64px'
        }} />
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-fuchsia-500">
            Simple, Transparent Pricing
          </h2>
          <p className="text-xl text-gray-400">Choose the perfect plan for your needs</p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {plans.map((plan) => (
            <motion.div
              key={plan.name}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              className="relative"
            >
              {plan.popular && (
                <div className="absolute -top-4 left-0 right-0 flex justify-center z-10">
                  <div className="bg-gradient-to-r from-violet-500 to-fuchsia-500 text-white text-sm font-bold px-4 py-1 rounded-full shadow-lg">
                    Most Popular
                  </div>
                </div>
              )}

              <div
                className={`relative h-full backdrop-blur-sm rounded-2xl p-8 transition-all duration-300 ${plan.popular
                  ? 'bg-purple-900/30 border-2 border-violet-500/50 shadow-xl shadow-purple-500/10'
                  : 'bg-purple-900/20 border border-purple-500/20 hover:border-purple-500/30'
                  }`}
              >
                <div className="text-center mb-8">
                  <h3 className="text-2xl text-white font-bold mb-2">{plan.name}</h3>
                  <p className="text-gray-400 mb-6">{plan.description}</p>
                  <div className="flex items-baseline justify-center gap-2">
                    <span className="text-4xl text-white font-bold">₹ {plan.price}</span>
                    <span className="text-gray-400">/month</span>
                  </div>
                </div>

                <div className="space-y-4 mb-8">
                  {plan.features.map((feature) => (
                    <div key={feature} className="flex items-start gap-3">
                      <CheckIcon className="h-6 w-6 text-purple-400 flex-shrink-0" />
                      <span className="text-gray-300">{feature}</span>
                    </div>
                  ))}
                </div>

                <button
                  onClick={() => setSelectedPlan(plan)}
                  className="w-full px-8 py-4 rounded-xl font-bold text-white bg-gradient-to-r from-purple-500 to-violet-500 hover:opacity-90 transition-all duration-300"
                >
                  Get Started
                </button>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}