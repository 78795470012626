import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { motion } from 'framer-motion'

interface MobileMenuProps {
  open: boolean
  onClose: () => void
}

const menuItems = [
  { name: 'AI Detector', path: '/ai-detector' },
  { name: 'AI Humanizer', path: '/ai-humanizer' },
  { name: 'AI Writer', path: '/ai-writer' },
  { name: 'Voiceover AI', path: '/voiceover-ai' },
  { name: 'Imagine AI', path: '/imagine-ai' },
  { name: 'Conversational AI', path: '/conversational-ai' }
]

const menuVariants = {
  hidden: { x: '100%' },
  visible: { x: 0 }
}

const itemVariants = {
  hidden: { opacity: 0, x: 20 },
  visible: { opacity: 1, x: 0 }
}

export default function MobileMenu({ open, onClose }: MobileMenuProps) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900/80 backdrop-blur-sm" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="w-screen max-w-md">
                  <motion.div
                    variants={menuVariants}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    className="flex h-full flex-col overflow-y-scroll glass py-6 shadow-2xl"
                  >
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-2xl font-bold gradient-text">
                          Menu
                        </Dialog.Title>
                        <button
                          type="button"
                          className="relative rounded-md p-2 hover:text-primary-400"
                          onClick={onClose}
                        >
                          <XMarkIcon className="h-6 w-6" />
                        </button>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      <motion.div
                        className="flex flex-col space-y-4"
                        variants={{
                          visible: {
                            transition: {
                              staggerChildren: 0.1
                            }
                          }
                        }}
                      >
                        {menuItems.map((item) => (
                          <motion.div
                            key={item.name}
                            variants={itemVariants}
                          >
                            <Link
                              to={item.path}
                              className="block px-4 py-3 text-lg hover:text-primary-400 transition-colors rounded-lg hover:bg-gray-800/50"
                              onClick={onClose}
                            >
                              {item.name}
                            </Link>
                          </motion.div>
                        ))}
                        <motion.div
                          variants={itemVariants}
                          className="pt-4"
                        >
                          <button className="w-full px-4 py-3 rounded-full text-lg font-medium bg-primary-500 hover:bg-primary-600 transition-all duration-300">
                            Get Started
                          </button>
                        </motion.div>
                      </motion.div>
                    </div>
                  </motion.div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
