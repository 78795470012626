import { useState } from 'react'
import { createCheckoutSession } from '../lib/stripe'

export default function PaymentMethods() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  
  const handlePaymentMethod = async (priceId: string) => {
    try {
      setLoading(true)
      await createCheckoutSession(priceId)
    } catch (err) {
      setError('Payment failed. Please try again.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="max-w-2xl mx-auto p-6">
      <h1 className="text-2xl font-bold mb-6">Choose Payment Method</h1>
      
      {error && (
        <div className="bg-red-50 text-red-500 p-4 mb-6 rounded">
          {error}
        </div>
      )}

      <div className="space-y-4">
        <button
          onClick={() => handlePaymentMethod('your_price_id')}
          disabled={loading}
          className="w-full p-4 border rounded-lg hover:bg-gray-50"
        >
          <div className="flex items-center">
            <img src="/credit-card.svg" alt="Credit Card" className="w-8 h-8 mr-3" />
            <div>
              <div className="font-semibold">Credit / Debit Card</div>
              <div className="text-sm text-gray-500">Visa, Mastercard, AMEX</div>
            </div>
          </div>
        </button>

        <button
          onClick={() => handlePaymentMethod('your_price_id')}
          disabled={loading}
          className="w-full p-4 border rounded-lg hover:bg-gray-50"
        >
          <div className="flex items-center">
            <img src="/bank.svg" alt="Bank" className="w-8 h-8 mr-3" />
            <div>
              <div className="font-semibold">Bank Account (ACH)</div>
              <div className="text-sm text-gray-500">US Bank Accounts</div>
            </div>
          </div>
        </button>
      </div>
    </div>
  )
}