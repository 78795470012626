import { useState, useRef, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { 
  ChatBubbleBottomCenterTextIcon,
  PaperAirplaneIcon,
  ArrowPathIcon,
  TrashIcon,
  SparklesIcon
} from '@heroicons/react/24/outline'
import AIServiceStatus from '../components/AIServiceStatus'

const personalities = [
  { id: 'professional', name: 'Professional', description: 'Formal and business-like' },
  { id: 'friendly', name: 'Friendly', description: 'Casual and approachable' },
  { id: 'creative', name: 'Creative', description: 'Imaginative and artistic' },
  { id: 'academic', name: 'Academic', description: 'Scholarly and detailed' }
]

interface Message {
  id: string
  role: 'user' | 'assistant'
  content: string
  timestamp: Date
}

export default function ConversationalAI() {
  const [messages, setMessages] = useState<Message[]>([])
  const [input, setInput] = useState('')
  const [loading, setLoading] = useState(false)
  const [personality, setPersonality] = useState(personalities[0])
  const messagesEndRef = useRef<HTMLDivElement>(null)

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  const handleSend = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!input.trim() || loading) return

    const userMessage: Message = {
      id: Date.now().toString(),
      role: 'user',
      content: input,
      timestamp: new Date()
    }

    setMessages(prev => [...prev, userMessage])
    setInput('')
    setLoading(true)

    try {
      // Simulate AI response - replace with actual API call
      await new Promise(resolve => setTimeout(resolve, 1000))
      const aiMessage: Message = {
        id: (Date.now() + 1).toString(),
        role: 'assistant',
        content: `This is a simulated response to: "${input}"`,
        timestamp: new Date()
      }
      setMessages(prev => [...prev, aiMessage])
    } catch (err) {
      console.error('Chat error:', err)
    } finally {
      setLoading(false)
    }
  }

  const clearChat = () => {
    setMessages([])
  }

  return (
    <div className="bg-[#0D0118] min-h-screen pt-32">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <AIServiceStatus />

        <div className="text-center mb-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="inline-flex items-center px-6 py-2 rounded-full glass text-purple-300 border border-purple-500/20 mb-8"
          >
            <ChatBubbleBottomCenterTextIcon className="h-5 w-5 mr-2" />
            Conversational AI
          </motion.div>

          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
            className="text-5xl sm:text-6xl font-bold mb-8"
          >
            Chat with our
            <br />
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-cyan-400 to-teal-500">
              Intelligent AI
            </span>
          </motion.h1>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="max-w-4xl mx-auto glass rounded-2xl p-8"
          >
            {/* Personality Selection */}
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mb-6">
              {personalities.map((p) => (
                <button
                  key={p.id}
                  onClick={() => setPersonality(p)}
                  className={`p-4 rounded-xl text-left transition-colors ${
                    personality.id === p.id
                      ? 'bg-blue-500/20 border border-blue-500/50'
                      : 'glass hover:bg-purple-900/30'
                  }`}
                >
                  <div className="font-medium">{p.name}</div>
                  <div className="text-sm text-gray-400">{p.description}</div>
                </button>
              ))}
            </div>

            {/* Chat Messages */}
            <div className="h-[500px] mb-6 overflow-y-auto rounded-xl glass p-4">
              <div className="space-y-4">
                {messages.length === 0 && (
                  <div className="text-center text-gray-400 py-8">
                    <SparklesIcon className="h-8 w-8 mx-auto mb-2" />
                    Start a conversation with AI
                  </div>
                )}
                
                <AnimatePresence>
                  {messages.map((message) => (
                    <motion.div
                      key={message.id}
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}
                    >
                      <div
                        className={`max-w-[80%] rounded-2xl p-4 ${
                          message.role === 'user'
                            ? 'bg-blue-500 text-white'
                            : 'bg-purple-900/40 text-gray-200'
                        }`}
                      >
                        <p>{message.content}</p>
                        <div className="text-xs opacity-50 mt-1">
                          {message.timestamp.toLocaleTimeString()}
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </AnimatePresence>
                
                {loading && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="flex justify-start"
                  >
                    <div className="bg-purple-900/40 rounded-2xl p-4 flex items-center gap-2">
                      <ArrowPathIcon className="h-5 w-5 animate-spin" />
                      AI is typing...
                    </div>
                  </motion.div>
                )}
                <div ref={messagesEndRef} />
              </div>
            </div>

            {/* Input Form */}
            <form onSubmit={handleSend} className="flex gap-4">
              <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                className="flex-1 bg-purple-900/20 rounded-xl px-4 py-3 text-gray-100 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500/50"
                placeholder="Type your message..."
                disabled={loading}
              />
              <button
                type="button"
                onClick={clearChat}
                className="px-4 py-3 rounded-xl font-medium glass hover:bg-purple-900/30 transition-colors"
                title="Clear chat"
              >
                <TrashIcon className="h-5 w-5" />
              </button>
              <button
                type="submit"
                disabled={loading || !input.trim()}
                className="px-6 py-3 rounded-xl font-bold text-white bg-gradient-to-r from-blue-500 to-cyan-500 hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
              >
                <PaperAirplaneIcon className="h-5 w-5" />
              </button>
            </form>
          </motion.div>
        </div>
      </div>
    </div>
  )
}
