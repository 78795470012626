import { motion } from 'framer-motion'

export default function XBeshBanner() {
  return (
    <motion.a
      href="https://xbesh.com"
      target="_blank"
      rel="noopener noreferrer"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 1 }}
      className="fixed bottom-4 right-4 glass px-4 py-2 rounded-full text-sm text-gray-300 hover:text-purple-400 transition-colors border border-purple-500/20 flex items-center gap-2 hover:border-purple-500/40 z-50"
    >
      Built with
      <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-500 font-semibold">
        xBesh
      </span>
    </motion.a>
  )
}
