import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { Elements } from '@stripe/react-stripe-js'
import { getStripe } from './lib/stripe'
import Layout from './components/Layout'
import Home from './pages/Home'
import AIDetector from './pages/AIDetector'
import AIHumanizer from './pages/AIHumanizer'
import AIWriter from './pages/AIWriter'
import VoiceoverAI from './pages/VoiceoverAI'
import ImagineAI from './pages/ImagineAI'
import ConversationalAI from './pages/ConversationalAI'
import PricingPage from './pages/PricingPage'
import Success from './pages/Success'
import Cancel from './pages/Cancel'
import PrivacyPolicy from './pages/PrivacyPolicy'
import Terms from './pages/Terms'
import RefundPolicy from './pages/RefundPolicy'
import XBeshBanner from './components/XBeshBanner'
import PaymentMethods from './pages/PaymentMethods'

export default function App() {
  return (
    <Elements stripe={getStripe()}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="ai-detector" element={<AIDetector />} />
          <Route path="ai-humanizer" element={<AIHumanizer />} />
          <Route path="ai-writer" element={<AIWriter />} />
          <Route path="voiceover-ai" element={<VoiceoverAI />} />
          <Route path="imagine-ai" element={<ImagineAI />} />
          <Route path="conversational-ai" element={<ConversationalAI />} />
          <Route path="pricing" element={<PricingPage />} />
          <Route path="success" element={<Success />} />
          <Route path="cancel" element={<Cancel />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms" element={<Terms />} />
          <Route path="refund-policy" element={<RefundPolicy />} />
          <Route path="payment-methods" element={<PaymentMethods />} />
        </Route>
      </Routes>
      <XBeshBanner />
    </Elements>
  )
}