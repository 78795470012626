import { motion } from 'framer-motion'
import Hero from '../components/Hero'
import Tools from '../components/Tools'
import ToolsShowcase from '../components/ToolsShowcase'
import Features from '../components/Features'
import HowItWorks from '../components/HowItWorks'
import Testimonials from '../components/Testimonials'
import Pricing from '../components/Pricing'

export default function Home() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="bg-[#0D0118]"
    >
      <Hero />
      <Tools />
      <ToolsShowcase />
      <Features />
      <HowItWorks />
      <Testimonials />
      <Pricing />
    </motion.div>
  )
}
