import OpenAI from 'openai'
import type { ChatCompletionMessageParam } from 'openai/resources/chat/completions'

const apiKey = import.meta.env.VITE_OPENAI_API_KEY

export type ChatMessage = {
  role: 'system' | 'user' | 'assistant'
  content: string
}


if (!apiKey) {
  console.error('OpenAI API key is missing!')
}

const openai = new OpenAI({
  apiKey: apiKey,
  dangerouslyAllowBrowser: true
})

export const testConnection = async () => {
  if (!apiKey) throw new Error('OpenAI API key is not configured')
  try {
    await openai.chat.completions.create({
      model: "gpt-3.5-turbo",
      messages: [{ role: "user", content: "Hello" }],
      max_tokens: 5
    })
    return true
  } catch (error) {
    console.error("OpenAI Connection Error:", error)
    throw error
  }
}

export const generateContent = async (prompt: string, type: string): Promise<string> => {
  if (!apiKey) throw new Error('OpenAI API key is not configured')
  try {
    const messages: ChatCompletionMessageParam[] = [
      {
        role: "system",
        content: `You are a content generator specialized in creating ${type} content. Create content based on the following prompt.`
      },
      { role: "user", content: prompt }
    ]
    const response = await openai.chat.completions.create({
      model: "gpt-3.5-turbo",
      messages,
      temperature: 0.8,
      max_tokens: 1000
    })
    return response.choices[0].message.content || "Content generation failed"
  } catch (error) {
    console.error("Content Generation Error:", error)
    throw error
  }
}

export const generateImage = async (prompt: string): Promise<string> => {
  if (!apiKey) throw new Error('OpenAI API key is not configured')
  try {
    const response = await openai.images.generate({
      model: "dall-e-3",
      prompt,
      n: 1,
      size: "1024x1024"
    })
    return response.data[0].url || ""
  } catch (error) {
    console.error("Image Generation Error:", error)
    throw error
  }
}

export const chatWithAI = async (messages: ChatCompletionMessageParam[]): Promise<string> => {
  if (!apiKey) throw new Error('OpenAI API key is not configured')
  try {
    const response = await openai.chat.completions.create({
      model: "gpt-3.5-turbo",
      messages,
      temperature: 0.7,
      max_tokens: 500
    })
    return response.choices[0].message.content || "Chat response failed"
  } catch (error) {
    console.error("Chat Error:", error)
    throw error
  }
}

export const detectAIContent = async (text: string): Promise<string> => {
  if (!apiKey) throw new Error('OpenAI API key is not configured')
  try {
    const messages: ChatCompletionMessageParam[] = [
      {
        role: "system",
        content: "You are an AI content detector. Analyze the following text and provide a detailed analysis with a confidence score percentage. Format your response as: 'AI Probability: X%\n\nAnalysis: [your detailed explanation]'"
      },
      { role: "user", content: text }
    ]
    const response = await openai.chat.completions.create({
      model: "gpt-3.5-turbo",
      messages,
      temperature: 0.7,
      max_tokens: 500
    })
    return response.choices[0].message.content || "Analysis failed"
  } catch (error) {
    console.error("AI Detection Error:", error)
    throw error
  }
}

export const humanizeText = async (text: string, tone: string): Promise<string> => {
  if (!apiKey) throw new Error('OpenAI API key is not configured')
  try {
    const messages: ChatCompletionMessageParam[] = [
      {
        role: "system",
        content: `You are a text humanizer. Rewrite the following text to sound more natural and human-like, using a ${tone} tone. Maintain the core message but make it feel more authentic and conversational.`
      },
      { role: "user", content: text }
    ]
    const response = await openai.chat.completions.create({
      model: "gpt-3.5-turbo",
      messages,
      temperature: 0.8,
      max_tokens: 500
    })
    return response.choices[0].message.content || "Humanization failed"
  } catch (error) {
    console.error("Humanizer Error:", error)
    throw error
  }
}

const openAIService = {
  testConnection,
  detectAIContent,
  humanizeText,
  generateContent,
  generateImage,
  chatWithAI
}

export default openAIService