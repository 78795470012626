import MarkdownPolicy from '../components/MarkdownPolicy'

const termsMd = `
# Terms and Conditions

*Last Updated: January 2024*

## Introduction

Welcome to AIUMAN. These Terms and Conditions govern your use of our services. By accessing or using our platform, you agree to these terms.

## 1. Definitions

### 1.1 Key Terms
- "Service" refers to AIUMAN's AI tools and platform
- "User" means any person accessing our services
- "Content" includes text, images, and other materials
- "AI Output" refers to AI-generated content

## 2. Service Access

### 2.1 Account Requirements
- Valid email address
- Accurate registration information
- Strong password maintenance
- Age requirement: 18 years or older

### 2.2 Account Security
- Keep credentials confidential
- Notify us of unauthorized access
- Regular password updates
- No credential sharing

## 3. Service Usage

### 3.1 Permitted Use
- Personal or business use
- Content creation and analysis
- AI model interaction
- Data processing within limits

### 3.2 Prohibited Activities
- Illegal content generation
- Service manipulation
- Unauthorized access attempts
- Harmful code distribution

## 4. Pricing and Payment

### 4.1 Subscription Terms
- Monthly/annual billing options
- Automatic renewals
- Payment processing security
- Price change notifications

### 4.2 Refund Conditions
- 7-day refund window
- Unused credit refunds
- Service outage compensation
- Billing error corrections

## 5. Intellectual Property

### 5.1 Ownership
- Platform rights reserved
- User content ownership
- AI output licensing
- Feedback rights

### 5.2 License Grants
- Platform usage rights
- Content sharing permissions
- API access terms
- Modification restrictions

## 6. Liability

### 6.1 Service Warranty
- "As-is" service provision
- No guaranteed availability
- Performance variations
- Maintenance windows

### 6.2 Limitation of Liability
- Indirect damages exclusion
- Maximum liability cap
- Force majeure events
- Data loss limitations

## 7. Termination

### 7.1 Termination Rights
- User-initiated termination
- Service-initiated termination
- Immediate suspension rights
- Account deletion process

## 8. Governing Law

### 8.1 Jurisdiction
These terms are governed by Indian law, with exclusive jurisdiction in New Delhi, India.

## Contact Information

NECTOR VENTURES PRIVATE LIMITED  
80, Karunakunj,  
Dwarka sector-3,  
New Delhi-110078  
India

---

*Last updated: January 1, 2024*
`

export default function Terms() {
  return <MarkdownPolicy title="Terms and Conditions" markdown={termsMd} />
}
