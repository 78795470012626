import { motion } from 'framer-motion'
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

export default function Hero() {
  return (
    <div className="relative min-h-screen flex items-center">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-b from-purple-600/10 via-violet-500/5 to-transparent" />
        <div className="absolute top-0 left-0 w-full h-full" style={{
          backgroundImage: `linear-gradient(to right, rgba(139, 92, 246, 0.05) 1px, transparent 1px),
                           linear-gradient(to bottom, rgba(139, 92, 246, 0.05) 1px, transparent 1px)`,
          backgroundSize: '64px 64px'
        }} />
        <div className="absolute top-1/4 -left-1/4 w-[50rem] h-[50rem] bg-purple-500/20 rounded-full blur-[128px]" />
        <div className="absolute bottom-1/4 -right-1/4 w-[50rem] h-[50rem] bg-violet-500/20 rounded-full blur-[128px]" />
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-32 pb-20">
        <div className="text-center">
          {/* Badge */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="inline-flex items-center px-6 py-2 rounded-full glass text-purple-300 border border-purple-500/20 mb-8"
          >
            <span className="text-sm font-medium">Transform Your Creative Process</span>
          </motion.div>

          {/* Main Title */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
            className="space-y-6"
          >
            <h1 className="text-6xl sm:text-7xl lg:text-8xl font-black tracking-tight leading-tight">
              <span className="text-white">ELEVATE</span>
              <br />
              <span className="text-white">YOUR CONTENT</span>
              <br />
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-400 via-violet-400 to-fuchsia-500">
                WITH AI
              </span>
            </h1>
            
            <p className="text-xl text-gray-300 max-w-3xl mx-auto">
              Harness the power of artificial intelligence to create exceptional content that stands out
            </p>
          </motion.div>

          {/* CTA Buttons */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="flex flex-col sm:flex-row gap-6 justify-center mt-12"
          >
            <Link
              to="/pricing"
              className="inline-flex items-center justify-center px-8 py-4 rounded-xl text-lg font-bold text-white bg-gradient-to-r from-purple-500 to-violet-500 hover:opacity-90 transition-all duration-300 transform hover:scale-105"
            >
              Get Started Free
              <ArrowRightIcon className="ml-2 h-5 w-5" />
            </Link>
            
            <Link
              to="/pricing"
              className="inline-flex items-center justify-center px-8 py-4 rounded-xl text-lg font-bold glass hover:bg-purple-900/30 transition-all duration-300"
            >
              View Pricing
            </Link>
          </motion.div>

          {/* Stats */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="mt-16 grid grid-cols-3 gap-8 max-w-2xl mx-auto"
          >
            {[
              ['1M+', 'Active Users'],
              ['100K+', 'Content Pieces'],
              ['99%', 'Satisfaction']
            ].map(([stat, label]) => (
              <div key={label} className="glass rounded-xl p-4">
                <div className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-fuchsia-500">
                  {stat}
                </div>
                <div className="text-sm text-gray-400 mt-1">{label}</div>
              </div>
            ))}
          </motion.div>
        </div>
      </div>
    </div>
  )
}
