import { motion } from 'framer-motion'
import { 
  MagnifyingGlassIcon, 
  UserIcon, 
  PencilSquareIcon,
  MicrophoneIcon,
  PhotoIcon,
  ChatBubbleBottomCenterTextIcon,
  ArrowRightIcon
} from '@heroicons/react/24/outline'

const tools = [
  {
    name: 'AI Detector',
    description: 'Detect AI-generated content with 99.9% accuracy using our advanced algorithms',
    icon: MagnifyingGlassIcon,
    gradient: 'from-purple-500 to-violet-500',
    link: '/ai-detector'
  },
  {
    name: 'AI Humanizer',
    description: 'Transform AI content into natural, human-like text that connects with readers',
    icon: UserIcon,
    gradient: 'from-violet-500 to-fuchsia-500',
    link: '/ai-humanizer'
  },
  {
    name: 'AI Writer',
    description: 'Create engaging content with our AI-powered writing assistant',
    icon: PencilSquareIcon,
    gradient: 'from-fuchsia-500 to-pink-500',
    link: '/ai-writer'
  },
  {
    name: 'Voiceover AI',
    description: 'Convert text to natural-sounding speech with emotional intelligence',
    icon: MicrophoneIcon,
    gradient: 'from-pink-500 to-rose-500',
    link: '/voiceover-ai'
  },
  {
    name: 'Imagine AI',
    description: 'Generate and edit stunning images with state-of-the-art AI',
    icon: PhotoIcon,
    gradient: 'from-rose-500 to-orange-500',
    link: '/imagine-ai'
  },
  {
    name: 'Chat AI',
    description: 'Build engaging chatbots and virtual assistants',
    icon: ChatBubbleBottomCenterTextIcon,
    gradient: 'from-orange-500 to-amber-500',
    link: '/chat-ai'
  }
]

export default function Tools() {
  return (
    <section className="py-24 relative overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold mb-4">Our AI Tools</h2>
          <p className="text-xl text-gray-400">Comprehensive suite of AI-powered tools for content creation</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {tools.map((tool, index) => (
            <motion.div
              key={tool.name}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="relative group"
            >
              <div className="relative glass rounded-2xl p-6 h-full hover:bg-purple-900/30 transition-colors">
                <div className={`h-12 w-12 rounded-xl bg-gradient-to-r ${tool.gradient} flex items-center justify-center mb-4`}>
                  <tool.icon className="h-6 w-6 text-white" />
                </div>
                <h3 className="text-xl font-bold mb-2">{tool.name}</h3>
                <p className="text-gray-400 mb-4">{tool.description}</p>
                <a 
                  href={tool.link}
                  className="inline-flex items-center text-purple-400 hover:text-purple-300 transition-colors"
                >
                  Learn more
                  <ArrowRightIcon className="h-4 w-4 ml-2" />
                </a>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  )
}
