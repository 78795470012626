import { motion } from 'framer-motion'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const customStyles = {
  h1: 'text-4xl font-bold mb-8 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-fuchsia-500',
  h2: 'text-2xl font-bold mt-12 mb-6 text-white border-b border-purple-500/20 pb-4',
  h3: 'text-xl font-semibold mt-8 mb-4 text-purple-300',
  p: 'text-gray-300 leading-relaxed mb-6',
  ul: 'mb-6 space-y-2 list-disc list-inside',
  ol: 'mb-6 list-decimal list-inside space-y-2',
  li: 'text-gray-300 ml-4',
  strong: 'text-purple-300 font-semibold',
  em: 'text-gray-400 italic',
  hr: 'my-8 border-purple-500/20',
}

interface MarkdownPolicyProps {
  title: string;
  markdown: string;
}

export default function MarkdownPolicy({ title, markdown }: MarkdownPolicyProps) {
  return (
    <div className="bg-[#0D0118] min-h-screen pt-32 pb-16">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8"
      >
        <div className="bg-purple-900/10 backdrop-blur-sm rounded-2xl p-8 border border-purple-500/20">
          {/* Header */}
          <div className="text-center mb-12">
            <h1 className={customStyles.h1}>{title}</h1>
            <div className="inline-block px-4 py-1 rounded-full bg-purple-900/20 border border-purple-500/20">
              <span className="text-sm text-gray-400">Last Updated: December 2024</span>
            </div>
          </div>

          {/* Content */}
          <div>
            <Markdown
              remarkPlugins={[remarkGfm]}
              components={{
                h1: ({node, ...props}) => <h1 className={customStyles.h1} {...props} />,
                h2: ({node, ...props}) => <h2 className={customStyles.h2} {...props} />,
                h3: ({node, ...props}) => <h3 className={customStyles.h3} {...props} />,
                p: ({node, ...props}) => <p className={customStyles.p} {...props} />,
                ul: ({node, ...props}) => <ul className={customStyles.ul} {...props} />,
                ol: ({node, ...props}) => <ol className={customStyles.ol} {...props} />,
                li: ({node, ...props}) => <li className={customStyles.li} {...props} />,
                strong: ({node, ...props}) => <strong className={customStyles.strong} {...props} />,
                em: ({node, ...props}) => <em className={customStyles.em} {...props} />,
                hr: ({node, ...props}) => <hr className={customStyles.hr} {...props} />,
              }}
            >
              {markdown}
            </Markdown>
          </div>

          {/* Contact Information */}
          <div className="mt-12 pt-8 border-t border-purple-500/20">
            <div className="bg-purple-900/20 rounded-xl p-6">
              <h3 className="text-xl font-semibold mb-4 text-purple-300">Contact Information</h3>
              <p className="text-gray-300">
                NECTOR VENTURES PRIVATE LIMITED<br />
                80, Karunakunj,<br />
                Dwarka sector-3,<br />
                New Delhi-110078<br />
                India
              </p>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  )
}
